<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Nieuw item" />

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Here you can provide the information needed for the item.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  
                  <div class="col-span-6 sm:col-span-3">
                    <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                    <input type="text" name="name" id="name" autocomplete="name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="person.name">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="function" class="block text-sm font-medium text-gray-700">Function</label>
                    <input type="text" name="function" id="function" autocomplete="family-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="person.function">
                  </div>

                  <div class="col-span-6 sm:col-span-6">
                    <label for="quote" class="block text-sm font-medium text-gray-700">Personal quote</label>
                    <input type="text" name="quote" id="quote" autocomplete="quote" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="person.quote">
                  </div>
                  
                </div>

              </div>
              
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="saveItem">
                  Next
                </button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>

  </main>
</template>

<script>
import PeopleService from '../../../services/admin/people/service';

export default {
  name: 'PersonNew',
  data() {
    return {
      person: {
        id: null,
        name: "",
        function: "",
        quote: "",
        description: ""
      },
      submitted: false
    }
  },
  methods: {
    saveItem() {
      console.log('saving')
      

      PeopleService.create(this.person)
        .then(data => {
          console.log(data.id);
          
          this.$router.push(`/people/${data.id}`);
        })
    },
    
    newItem() {
      this.submitted = false;
      this.person = {};
    }
  }
}
</script>